.reports-header {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 1024px) {
        align-self: unset;
        padding-left: unset;
    }

    @media (max-width: 560px) {
        flex-direction: column;
        gap: 10px;
    }

    h2 {
        font-family: 'Guardian MD';
        font-size: 2.2rem;

        @media (max-width: 400px) {
            font-size: 1.8rem;
            text-align: center;
        }
    }

    .title-container {
        display: flex;
        gap: 30px;

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            & {
              margin-bottom: 30px;
            }
        }

        .selection {
            display: flex;
            gap: 10px;
            justify-content: center;

            button {
                height: 40px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                background-color: #ABABAB;
                border-radius: 5px;

                &:hover {
                    background-color: #D9D9D9;

                    .search-icon path {
                        fill: #ABABAB;
                    }
                }

                @supports (-webkit-touch-callout: none) and (not (translate: none)) {
                    &:not(:last-child) {
                      margin-right: 10px;
                    }
                }
            }
        }

        a {
            font-family: 'Guardian MD';
            height: 40px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: #ABABAB;
            border-radius: 5px;
            text-decoration: none;
            color: black;

            &:hover {
                background-color: #D9D9D9;
            }
        }

    }

    button {
        height: 40px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: #ABABAB;
        border-radius: 5px;

        &:hover {
            background-color: #D9D9D9;

            .search-icon path {
                fill: #ABABAB;
            }
        }
    }

    #search-container {
        display: flex;
        gap: 15px;
        align-self: start;

        @media (max-width: 560px) {
            flex-direction: column;
            gap: 10px;
            align-self: unset;
            align-items: center;
        }

        select {
            height: 30px;
            width: 115px;
            padding: 5px;
            border: none;
            border-radius: 3px;
            background: #D9D9D9;

            &.hidden {
                display: none;
            }

            @supports (-webkit-touch-callout: none) and (not (translate: none)) {
                &:not(:last-child) {
                  margin-right: 5%;
                  margin-bottom: 0;
                }
              }
        }

        input[type=week], input[type=date] {
            height: 30px;
            width: 150px;
            padding: 5px;
            border: none;
            border-radius: 3px;
            background: #D9D9D9;

            &.hidden {
                display: none;
            }

            @supports (-webkit-touch-callout: none) and (not (translate: none)) {
                &:not(:last-child) {
                  margin-right: 5%;
                  margin-bottom: 0;
                }
              }
        }

        button {
            height: 30px;
            width: 115px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            border: none;
            border-radius: 3px;
            background-color: #ABABAB;
            color: #D6D6D6;

            .search-icon path {
                fill: #D9D9D9;
            }

            &:hover {
                background-color: #D9D9D9;
                color: #ABABAB;

                .search-icon path {
                    fill: #ABABAB;
                }
            }
        }

        .search-wrapper {
            display: flex;
            gap: 15px;

            @supports (-webkit-touch-callout: none) and (not (translate: none)) {
                & {
                  margin-bottom: 15px;
                }
            }
        }
    }

    #search-container.hidden {
        display: none;
    }
}

.message-wrapper {
    span {
        background-color: #e4270d;
        color: #fff;
        font-family: "Mulish Regular";
        font-size: 0.8rem;
        width: fit-content;
        align-self: center;
        padding: 5px;
        border-radius: 8px;
        display: none;
    }
}

.reports-body {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 1024px) {
        align-self: unset;
        padding-left: unset;
    }

    .report-element {
        display: flex;
        flex-direction: column;
        border: 1px solid;
        border-radius: 10px;
        background-color: #929292;
        color: #eeeeee;
        padding: 20px;
        gap: 10px;
        cursor: pointer;

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            &:not(:last-child) {
              margin-bottom: 5%;
            }
          }

        h3 {
            font-family: 'Guardian MD';
            font-size: 1.4rem;
        }

        .report-body {
            display: flex;
            flex-direction: row;
            gap: 30px;
            justify-content: space-between;

            @media (max-width: 1024px) {
                gap: 10em;
            }

            @media (max-width: 768px) {
                gap: 5em;
            }

            @media (max-width: 560px) {
                flex-direction: column;
                gap: 25px;
            }

            .report-wrapper {
                display: flex;
                flex-direction: column;

                b {
                    font-family: 'Guardian Bold';
                }

                span {
                    font-family: 'Guardian Regular';
                }

            }
        }

        &.expense {
            @media (max-width: 1024px) {
                min-width: calc(100vw - 95px);
            }
        }
    }

    .expenses-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .expenses-table {
            border: 1px solid #ABABAB;
            color: #ABABAB;
            width: 100%;
            margin-top: 10px;

            ul {
                list-style: none;

                li {
                    display: flex;
                    justify-content: flex-end;
                    padding: 0 10px;
                    font-size: 15px;
                    gap: 20px;
                }
            }
        }
    }

    .new-report-wrapper {
        display: flex;
        gap: 20px;

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            & {
              margin-bottom: 10px;
            }
          }

        div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 5px;

            @supports (-webkit-touch-callout: none) and (not (translate: none)) {
                & {
                  margin-left: 20px;
                }
              }

            @media (max-width: 560px) {
                width: -webkit-fill-available;
            }

            label {
                font-family: 'Guardian MD';
                font-size: 0.7rem;
                align-self: flex-start;
            }

            input {
                padding: 10px;
                border-radius: 8px;
                border: 1px solid #F0F1F7;
                background-color: #F8F9F9 !important;

                &[type= 'number'] {
                    max-width: 150px;
                }

                &#description {
                    min-width: 420px;

                    @media (max-width: 560px) {
                        min-width: unset;
                        width: 100%;
                    }
                }

                &#value {
                    min-width: 267px;

                    @media (max-width: 560px) {
                        min-width: unset;
                    }
                }
            }

            select {
                min-width: 300px;
                padding: 10px;
                border-radius: 8px;
                border: 1px solid #F0F1F7;
                background-color: #F8F9F9 !important;

                @media (max-width: 560px) {
                    min-width: unset;
                }
            }

            button {
                min-height: 35px;
                min-width: 90px;
                font-size: 20px;
                color: #ffffff;
                background-color: #B6B6B6;
                border: none;
                border-radius: 5px;

                &:active {
                    border: 1px solid #B6B6B6;
                    background-color: #ffffff;
                    color: #B6B6B6;
                }
            }
        }

        &.save {
            justify-content: center;
            padding: 10px;

            button {
                height: 40px;
                min-width: 110px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                font-family: Guardian MD;
                font-size: 16px;
                background-color: #018D97;
                color: #ffffff;
                border-radius: 5px;
                padding: 0 10px;

                &:hover {
                    background-color: #018D97d1;
                }
            }
        }

        &.delete {
            justify-content: center;
            padding: 10px;

            button {
                height: 40px;
                min-width: 110px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                font-family: Guardian MD;
                font-size: 16px;
                background-color: #970101;
                color: #ffffff;
                border-radius: 5px;
                padding: 0 10px;

                &:hover {
                    background-color: #970101d1;
                }
            }
        }
    }
}
