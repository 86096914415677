.button-reports {
    height: 40px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: Guardian MD;
    background-color: #394299;
    color: #ffffff;
    border-radius: 5px;

    &:hover {
        background-color: #394299d1;
    }
}