.internal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;
    padding: 0.5rem 1rem;
    background-color: #f9f9f9;
    max-height: 60px;
  
    button {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
  
    .header-content-left {
        display: flex;
        
        svg {
            height: 30px;
            width: 220px;

            @media (max-width: 425px) {
              width: 200;
            }
        }
    }

    .header-content-right {
      display: flex;
      align-items: center;
      gap: 10px;
  
      .user-info {
        display: flex;
        align-items: center;
        margin-left: 1rem;
  
        img {
          border-radius: 50%;
          margin-right: 0.5rem;
        }
  
        .user-info-name {
          display: flex;
          flex-direction: column;
          margin-right: 1rem;
          align-items: center;
  
          @media (max-width: 425px) {
            display: none;
          }
  
          .name {
            font-family: 'Roboto Medium';
            font-style: normal;
            font-size: 13px;
            line-height: 20px;
            color: #000000;
          }
  
          .subscription-type {
            font-family: 'Roboto Regular';
            font-style: normal;
            font-size: 13px;
            line-height: 20px;
            color: #545454;
          }
        }
      }
    }
  }