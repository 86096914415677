.loading-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: #6b6b6bb6;
    display: none;
    z-index: 100;

    &.show {
        display: flex;
    }

    align-items: center;
    justify-content: center;

    img {
        height: 200px;
    }
}