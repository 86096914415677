.main-title {
    text-align: center;
    font-size: 4rem;
    font-family: 'Guardian MD';

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
    }

    @media (max-width: 768px) {
        font-size: 3.3rem;
    }

    @media (max-width: 480px) {
        font-size: 2.9rem;
    }
}