.footer-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 10px;
    background-color: #F8F9F9;
    font-family: 'Guardian MD';
    font-size: 12px;
    text-align: center;

    a {
        text-decoration: none;
        color: #000000;

        &:hover {
            color: #ABABAB;
        }
    }
}