.report-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.month-container {
        width: 600px;
    }
    
    .report-title {
        display: flex;
        justify-content: center;
        gap: 10px;
        padding: 20px;
    
        h2 {
            font-family: 'Guardian Regular';
        }

        h1 {
            font-family: 'Guardian Regular';
            color: #70ad46;
            font-size: 27px;
        }
    }
    
    .report-body {
        max-width: 1200px;
        display: flex;
        justify-content: center;
    
        .report-wrapper {
            width: -webkit-fill-available;

            .report-header {
                padding-bottom: 30px;
            }

            .report-label {
                font-family: 'Guardian Regular';
                font-size: 20px;
            }

            .report-resume {
                padding-top: 15px;

                table {
                    border-collapse: collapse;
                    width: -webkit-fill-available;
                }

                td, th {
                    border: 1px solid black;
                    padding: 5px;
                }
            }
            
            .row {
                width: 100%;
                display: flex;
                justify-content: space-between;

                &.month {
                    padding-top: 20px;
                }

                &.total-entries {
                    padding-top: 40px;
                }
                
                &.title {
                    padding-top: 40px;

                    .report-label {
                        font-family: 'Guardian Bold';
                        font-size: 16px;
                    }
                }

                &.month {
                    .main-table {
                        font-family: 'Guardian Regular';
                        border: 1px solid;

                        td {
                            padding-left: 10px;
                        }
                    }
                }

                .main-table {
                    border-collapse: collapse;
                    width: -webkit-fill-available;

                    thead {
                        border-bottom: 1px solid #000;
                        color: #e4270d;
                    }

                    .entries {
                        display: flex;
                        flex-direction: column;
                    }

                    .bordered {
                        border: 1px solid black;
                    }
                }

                .column {
                    display: flex;
                    flex-direction: column;
                    min-width: 240px;

                    table {
                        border-collapse: collapse;
                        width: 100%;
                    }

                    th {
                        text-align: start;
                    }

                    td, th {
                        padding: 5px;
                    }
                }
            }
        }
    
    }
}