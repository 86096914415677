body, * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
}

@font-face {
    font-family: 'Guardian Regular';
    src: url("../assets/fonts/GuardianSans/GuardianSans-Regular.otf")
}

@font-face {
    font-family: 'Guardian MD';
    src: url("../assets/fonts/GuardianSans/GuardianSans-Medium.otf")
}

@font-face {
    font-family: 'Guardian Semibold';
    src: url("../assets/fonts/GuardianSans/GuardianSans-Semibold.otf")
}

@font-face {
    font-family: 'Guardian Bold';
    src: url("../assets/fonts/GuardianSans/GuardianSans-Bold.otf")
}

@font-face {
    font-family: 'Mulish Bold';
    src: url("../assets/fonts/Mulish/Mulish-Bold.ttf")
}

@font-face {
    font-family: 'Mulish Regular';
    src: url("../assets/fonts/Mulish/Mulish-Regular.ttf")
}