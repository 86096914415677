.subtitle-element {
    font-family: "Guardian Regular";
    font-size: 2.4rem;
    text-align: center;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
    }

    @media (max-width: 768px) {
        font-size: 2.3rem;
    }

    @media (max-width: 480px) {
        font-size: 1.9rem;
    }
}