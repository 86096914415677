.header-element {
    background-color: #F8F9F9;
    min-height: 160px;

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        min-height: inherit;

        .header-logo {
            width: 290px;
        }

        nav {
            display: flex;
            gap: 20px;

            @media (max-width: 500px) {
                flex-direction: column;
                gap: 3px;
            }

            a {
                height: 40px;
                min-width: 110px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-family: Guardian MD;
                font-size: 16px;

                &.link-reports {
                    background-color: #394299;
                    color: #ffffff;
                    border-radius: 5px;

                    &:hover {
                        background-color: #394299d1;
                    }

                    @media (max-width: 500px) {
                        border-radius: 0;
                    }
                }

                &.link-login {
                    background-color: #D9D9D9;
                    color: #0E1318;
                    border-radius: 5px;

                    &:hover {
                        background-color: #D9D9D9d1;
                    }

                    @media (max-width: 500px) {
                        border-radius: 0;
                    }
                }

                &.create-report {
                    
                    color: #ffffff;
                    border-radius: 5px;
                    padding: 0 10px;

                    &.month {
                        background-color: #5d94c4;
                        &:hover {
                            background-color: #5d94c4d1;
                        }
                    }

                    &.week {
                        background-color: #018D97;
                        &:hover {
                            background-color: #018D97d1;
                        }
                    }              

                    @media (max-width: 500px) {
                        border-radius: 0;
                    }
                }

                @media (max-width: 500px) {
                    width: 100vw;
                }
            }

            button {
                height: 40px;
                width: 110px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                font-family: Guardian MD;
                font-size: 16px;

                &.button-logout {
                    background-color: #d45928;
                    color: #ffffff;
                    border-radius: 5px;

                    &:hover {
                        background-color: #d66f46;
                    }

                    @media (max-width: 500px) {
                        border-radius: 0;
                    }
                }

                @media (max-width: 500px) {
                    width: 100vw;
                }
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 15px;
        }
    }

}