.user-header {
    display: flex;
    flex-direction: column;
    align-self: start;
    padding-left: 10rem;
    gap: 10px;

    @media (max-width: 1024px) {
        align-self: unset;
        padding-left: unset;
    }

    @media (max-width: 560px) {
        flex-direction: column;
        gap: 10px;
    }

    h2 {
        font-family: 'Guardian MD';
        font-size: 2.2rem;

        @media (max-width: 400px) {
            font-size: 1.8rem;
            text-align: center;
        }
    }

    .title-container {
        display: flex;
        align-items: center;
        gap: 30px;

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            & {
              margin-bottom: 30px;
            }
        }

    }

    a {
        height: 40px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: #ABABAB;
        border-radius: 5px;
        color: black;
        text-decoration: none;
        font-family: 'Guardian MD';

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            & {
              margin-right: 20px;
            }
        }

        &:hover {
            background-color: #D9D9D9;

            .search-icon path {
                fill: #ABABAB;
            }
        }
    }
}

.user-body {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding-left: 10rem;
    gap: 30px;

    .decimists-table {
        display: flex;
        flex-direction: column;

        .decimists-list {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 0px;

            li {
                display: flex;
                flex-direction: row;
                gap: 20px;
                border: 1px solid #000;

                b {
                    padding-left: 15px;
                }

                span {
                    border-left: 1px solid #000;
                    padding-left: 15px;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        align-self: unset;
        padding-left: unset;
    }

    .new-decimist-wrapper {
        display: flex;
        gap: 20px;

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            & {
              margin-bottom: 10px;
            }
        }

        div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 5px;
            min-width: 300px;
            margin-bottom: 10px;

            @media (max-width: 560px) {
                width: -webkit-fill-available;
            }

            label {
                font-family: 'Guardian MD';
                font-size: 0.7rem;
                align-self: flex-start;
            }

            input {
                padding: 10px;
                border-radius: 8px;
                border: 1px solid #F0F1F7;
                background-color: #F8F9F9 !important;

                &[type= 'number'] {
                    max-width: 150px;
                }

                &#decimist {
                    min-width: 420px;

                    @media (max-width: 560px) {
                        min-width: unset;
                        width: 100%;
                    }
                }

                &#value {
                    min-width: 267px;

                    @media (max-width: 560px) {
                        min-width: unset;
                    }
                }
            }

            select {
                min-width: 300px;
                padding: 10px;
                border-radius: 8px;
                border: 1px solid #F0F1F7;
                background-color: #F8F9F9 !important;

                @media (max-width: 560px) {
                    min-width: unset;
                }
            }

            button {
                min-height: 35px;
                min-width: 90px;
                font-size: 20px;
                color: #ffffff;
                background-color: #B6B6B6;
                border: none;
                border-radius: 5px;

                &:active {
                    border: 1px solid #B6B6B6;
                    background-color: #ffffff;
                    color: #B6B6B6;
                }
            }
        }
    }

    .new-user-wrapper {
        display: flex;
        gap: 20px;

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            & {
              margin-bottom: 10px;
            }
          }

        div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 5px;
            min-width: 300px;
            margin-bottom: 20px;

            @media (max-width: 560px) {
                width: -webkit-fill-available;
            }

            label {
                font-family: 'Guardian MD';
                font-size: 0.7rem;
                align-self: flex-start;
            }

            input {
                padding: 10px;
                border-radius: 8px;
                border: 1px solid #F0F1F7;
                background-color: #F8F9F9 !important;

                &[type= 'number'] {
                    max-width: 150px;
                }

                &#description {
                    min-width: 420px;

                    @media (max-width: 560px) {
                        min-width: unset;
                        width: 100%;
                    }
                }

                &#value {
                    min-width: 267px;

                    @media (max-width: 560px) {
                        min-width: unset;
                    }
                }
            }

            select {
                min-width: 300px;
                padding: 10px;
                border-radius: 8px;
                border: 1px solid #F0F1F7;
                background-color: #F8F9F9 !important;

                @media (max-width: 560px) {
                    min-width: unset;
                }
            }

            button {
                min-height: 35px;
                min-width: 90px;
                font-size: 20px;
                color: #ffffff;
                background-color: #B6B6B6;
                border: none;
                border-radius: 5px;

                &:active {
                    border: 1px solid #B6B6B6;
                    background-color: #ffffff;
                    color: #B6B6B6;
                }
            }
        }

        &.save {
            justify-content: center;
            padding: 10px;

            button {
                height: 40px;
                min-width: 110px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                font-family: Guardian MD;
                font-size: 16px;
                background-color: #018D97;
                color: #ffffff;
                border-radius: 5px;
                padding: 0 10px;

                &:hover {
                    background-color: #018D97d1;
                }
            }
        }

        &.delete {
            justify-content: center;
            padding: 10px;

            button {
                height: 40px;
                min-width: 110px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                font-family: Guardian MD;
                font-size: 16px;
                background-color: #970101;
                color: #ffffff;
                border-radius: 5px;
                padding: 0 10px;

                &:hover {
                    background-color: #970101d1;
                }
            }
        }
    }
}