.reset-password-wrapper {
    border: none;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    width: 410px;
  
    @media (max-width: 480px) {
      width: 300px;
    }
  
    .reset-password-header {
      padding-bottom: 30px;
  
      h1 {
        font-family: "Mulish Bold";
        font-size: 2.2rem;
      }
  
      span {
        font-family: "Mulish Regular";
        font-size: 1.1rem;
      }
  
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      div {
        display: flex;
        flex-direction: column;
        gap: 5px;

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
          & {
            margin-bottom: 10px;
          }
        }
      }
  
      label {
        font-family: 'Guardian MD';
        font-size: 0.7rem;
        align-self: flex-start;
      }
  
      input {
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #F0F1F7;
        background-color: #F8F9F9 !important;
      }
  
      button {
        padding: 10px;
        border-radius: 8px;
        border: none;
        font-family: "Guardian MD";
        font-size: 1rem;
        color: #fff;
        background-color: #394299;

        &:hover {
          background-color: #394299d1;
        }
      }

      .message-wrapper {
        span {
            background-color: #e4270d;
            color: #fff;
            font-family: "Mulish Regular";
            font-size: 0.8rem;
            width: fit-content;
            align-self: center;
            padding: 5px;
            border-radius: 8px;
            display: none;
        }
  
        span.success {
            background-color: #d7f1c8;
            color: #000;
        }
      }
    }
  }