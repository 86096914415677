.verse-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
    }

    .verse-element {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        span {
            max-width: 400px;
            font-family: 'Guardian Regular';
        }

        b {
            font-family: 'Guardian Semibold';
        }
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
}