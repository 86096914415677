.aside-menu-container {
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 11;
    transition: all .4s ease;
    left: -100%;

    &.open {
        left: 0;
    }

    .aside-menu-content {
        height: 100%;

        .aside-menu-content-header {
            display: flex;
            align-items: center;
            gap: 15px;
            background-color: #14182E;
            padding: 13px;
            vertical-align: middle;

            .menu-button {
                background-color: transparent;
                border: none;
                padding: 0;
                margin: 0;
                cursor: pointer;
                margin-top: 7px;
                @supports (-webkit-touch-callout: none) and (not (translate: none)) {
                    & {
                      margin-top: 0;
                      margin-right: 10px;
                    }
                  }
            }

            .logo-text {
                font-family: 'Guardian Semibold';
                font-size: 20px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #ffffff;
            }
        }

        .aside-menu-content-body {
            background-color: #202541;
            height: 100%;
            padding-top: 15px;

            .aside-menu-list {
                li {
                    padding: 15px 13px;
                    display: flex;
                    flex-direction: column;

                    &:hover {
                        background-color: #2F3A5A;
                    }

                    a {
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        gap: 15px;

                        b {
                            font-family: 'Guardian Regular';
                            font-size: 14px;
                            line-height: 28px;
                            display: flex;
                            align-items: center;
                            color: #FFFFFF;
                        }
                    }

                    &.remove {
                        b {
                            color: #e41919
                        }
                    }
                }
            }
        }
    }
}