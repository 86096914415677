.profile-menu-container {
    position: fixed;
    right: 10px;
    top: -100px;
    z-index: 1;
    width: 160px;
    height: 55px;
    padding: 15px;
    background-color: #202541;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.4s ease;

    &.open {
        top: 60px;
    }

    .profile-menu-content {

        .profile-menu-list {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .profile-menu-item {

                button {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    justify-content: flex-start;

                    background-color: transparent;
                    border: none;
                    padding: 0;
                    margin: 0;
                    cursor: pointer;

                    b {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}