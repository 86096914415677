.users-header {
    display: flex;
    flex-direction: column;
    padding-left: 5rem;
    gap: 10px;

    @media (max-width: 1024px) {
        align-self: unset;
        padding-left: unset;
    }

    @media (max-width: 560px) {
        flex-direction: column;
        gap: 10px;
    }

    h2 {
        font-family: 'Guardian MD';
        font-size: 2.2rem;

        @media (max-width: 400px) {
            font-size: 1.8rem;
            text-align: center;
        }
    }

    .title-container {
        display: flex;
        gap: 30px;

        a {
            font-family: 'Guardian MD';
            height: 40px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: #ABABAB;
            border-radius: 5px;
            text-decoration: none;
            color: black;

            &:hover {
                background-color: #D9D9D9;
            } 
        }
    }
}

.users-container {
    table.users-table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 5px;
        font-family: "Guardian Regular";

        &#decimists-table {
            width: 100%;
        }
    }

    td.editable {
        cursor: url('../assets/img/edit.ico'), copy;
    }

    table.users-table {
        th {
            background-color: #ABABAB;
        }
    }
}

.update-data-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: -webkit-fill-available;
    background-color: rgba(0,0,0, 0.8);

    button.close-modal {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 10px;
        font-family: 'Guardian Semibold';
        font-size: 20px;
        border: 0;
        background: transparent;
        color: #fff;
        cursor: pointer;
    }

    .update-data-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 20px;
        background-color: #ABABAB;
        border-radius: 10px;

        span.main-label {
            font-family: 'Guardian Regular';
        }

        select {
            padding: 5px;
            text-align: center;
            border: none;
            border-radius: 5px;
        }

        input[type='email'] {
            padding: 5px;
            border: none;
            border-radius: 5px;
        }

        button.update-data {
            height: 40px;
                min-width: 110px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                font-family: Guardian MD;
                font-size: 16px;
                background-color: #018D97;
                color: #ffffff;
                border-radius: 5px;
                padding: 0 10px;

                &:hover {
                    background-color: #018D97d1;
                }
        }

        .message-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}